<template>
  <div class="tw_upload_drawer permit">
    <el-drawer class="drawer" :visible.sync="drawerShow" :withHeader="false" size="1082px" :before-close="closeConfirm">
      <tw-collapse :title="$t('Label.L/C File Upload')" :initial-open="true" :to-top="true">
        <!-- アップロードボタン -->
        <div class="buttons" v-if="canUpload && !isError">
          <tw-button
            type="default"
            size="medium"
            @click="cancelConfirm"
          >
            Cancel
          </tw-button>
          <tw-button
            type="primary"
            size="medium"
            @click="confirm"
          >
            OK
          </tw-button>
        </div>

        <!-- アップロード失敗時のボタン -->
        <div class="buttons" v-if="isError">
          <tw-button
            type="primary"
            size="medium"
            @click="cancel"
          >
            OK
          </tw-button>
        </div>

        <!-- アップロードエリア -->
        <div v-show="!canUpload" class="attachment" @dragover.prevent="dragEnter" @dragleave.prevent="dragLeave" @drop.prevent="dropFile" :class="{enter: dragin}" multiple>
          <div class="attach">
            <tw-button type="secondary" size="medium" class="choose_file" @click="loadFiles">Choose Local File<input ref="filesInput" class="file_input" @change="setFile" type="file" multiple accept=".tsv"></tw-button>
          </div>
          <div class="droparea">
            <img src="@/assets/images/dropfile.svg">
            Drop file here to upload.
          </div>
        </div>

        <!-- トグルボタン -->
        <tw-button v-if="canUpload && res" type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle" style="margin-left: 16px">
          <img src="@/assets/images/icons/view_all.svg">
        </tw-button>

        <div class="view_area" v-if="canUpload">
          <table class="card">
            <template v-if="res && Object.keys(res).length">
              <TwLcPoolProcessList :initialOpen="true" :initSelectLc="true" :isUpload="true" ref="processList" />
            </template>
          </table>
        </div>

        <div class="circle" @click="closeConfirm" />
      </tw-collapse>
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { decodeBuffer } from '@/utils/tsv';

// components
import TwButton from '@/components/atoms/TwButton';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwLcPoolProcessList from '@/components/organisms/TwLcPoolProcessList';

export default {
  name: 'TwLcUpload',
  components: {
    TwLcPoolProcessList,
    TwButton,
    TwCollapse,
  },
  provide() {
    return {
      s: this,
      activeTab: null
    }
  },
  props: {
    isShowUpload: Boolean,
  },
  data() {
    return {
      drawerShow: false,
      res: {}, // TSVファイルパースしたJSONデータ格納（画面表示用）
      uploadData: {}, // アップロード用のJSONデータ格納
      fileList: [], // 取得したファイルを格納するリスト
      dragin: false,
      documentsForm: {
        documents: [], // 最終的にBFFに渡すリスト
      },
      canUpload: false, // TSVファイルパース後、アップロード可能な状態か
      isError: false, // アップロード後にエラーがあった場合true
      expand: true,
    };
  },
  computed: {},
  created() {},
  watch: {
    isShowUpload(val) {
      this.canUpload = false;
      this.isError = false;
      this.drawerShow = val;
    }
  },
  methods: {
    cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$refs.processList.openAll();
      else this.$refs.processList.closeAll();
    },
    close() {
      this.res = {};
      this.uploadData = {};
      this.canUpload = false;
      this.isError = false;
      this.$emit('close');
    },
    closeConfirm() {
      if (this.canUpload) {
        this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          this.close();
        })
        .catch(() => {});
      } else this.close();
    },
    end() {
      this.close();
      this.$store.commit('END_PROCESS');
    },
    // アップロード処理
    loadFiles() {
      this.$refs.filesInput.click();
    },
    loadDirectory() {
      this.$refs.directoryInput.click();
    },
    async setFile(e) {
      this.$store.commit('START_PROCESS');
      // ファイルを取得して、fileList[]に格納
      this.fileList = [];
      this.documentsForm.documents = [];
      if (_.get(e, 'target.files[0]')) {
        // ファイル選択（input）の場合の処理
        const files = e.target.files;
        _.forEach(files, file => {
          this.fileList.push(file);
        });
      } else if (_.get(e, 'dataTransfer.files') && _.get(e, 'dataTransfer.files').length > 0) {
        // ドラッグアンドドロップの場合の処理
        const files = _.get(e, 'dataTransfer.files')
        _.forEach(files, file => {
          this.fileList.push(file);
        });
      } else {
        this.end();
        this.$store.dispatch('Sorry, FileReader API not supported');
        return
      }

      if (!this.fileList.length) {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Only files with the following extensions are allowed: TSV');
        return;
      }

      // ファイルをチェック
      // console.log(this.fileCheck(this.fileList))
      if (!this.fileCheck(this.fileList)) {
        this.$store.commit('END_PROCESS');
        return
      }

      if (typeof FileReader === 'function') {
        for (let i = 0; i < this.fileList.length; i++) {
          const file = this.fileList[i];
          await this.createFile(file)
          .then(() => {})
          .catch(() => {
            this.end();
            this.$store.dispatch('Sorry, FileReader API not working');
            return
          });
        }
        // アップロードBFF実行
        await this.tsvParse()
      } else {
        this.end();
        this.$store.dispatch('Sorry, FileReader API not supported');
      }
    },
    // ファイルを格納
    async createFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const decodedString = await decodeBuffer(reader, file);
          // console.log(decodedString)
          // console.log(this.formatBase64(decodedString))
          this.documentsForm.documents.push({
            fileName: file.name,
            fileValue: this.formatBase64(decodedString),
          });
          resolve();
        };
        reader.onerror = () => {
          reject();
        };
        reader.readAsArrayBuffer(file);
      })
    },
    // 拡張子を取得します
    getFileExtension(filename) {
      const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
      return extension.toLowerCase();
    },
    // 読み取り結果をbase64に変換します
    formatBase64(str) {
      if (str) return Buffer.from(str).toString('base64');
      else return ''
    },
    // ファイルチェック
    fileCheck(files) {
      // console.log(files)
      if (files.length > 1) {
        this.$store.dispatch('SHOW_ALERT', 'Maximum number of files[1] exceeded.');
        return false;
      }
      if (_.some(files, file => {
        if (file.name.length > 100) {
          this.$store.dispatch('SHOW_ALERT', 'Maximum length of file name[100(including extensions)] exceeded.');
          return true;
        }
      })) {
        return false;
      }
      if (_.some(files, file => {
        if (file.size > 10485760) {
          this.$store.dispatch('SHOW_ALERT', 'Your file exceeds the maximum upload size: 10.0MB');
          return true;
        }
        if (file.size === 0) {
          this.$store.dispatch('SHOW_ALERT', 'Your file cannot be read');
          return true;
        }
      })) {
        return false;
      }
      if (_.some(files, file => {
        // console.log(this.getFileExtension(file.name))
        if (!['tsv'].includes(this.getFileExtension(file.name))) {
          this.$store.dispatch('SHOW_ALERT', 'Only files with the following extensions are allowed: tsv');
          return true;
        }
      })) {
        return false;
      }
      return true
    },
    dragEnter() {
      this.dragin = true;
    },
    dragLeave() {
      this.dragin = false;
    },
    dropFile(e) {
      this.dragin = false;
      this.setFile(e)
    },
    // TSV→JSON変換BFF
    // BFF_LCP06 TSVファイル入力共通BFF
    async tsvParse() {
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/lc-pool-datas/upload',
        },
        data: this.documentsForm.documents[0],
      };
      $api.request(params)
      .then(res => {
        this.uploadData.lcpools = _.cloneDeep(res.lcpools);
        this.uploadData.lcpools = _.map(this.uploadData.lcpools, pool => {
          return pool.lcdata;
        });
        this.$set(this.res, 'linkageLc', {
          lcpools: res.lcpools,
        });
        this.canUpload = true;
        this.$store.commit('END_PROCESS');
        return Promise.resolve(res);
      })
      .catch(err => {
        this.res = {};
        this.uploadData = {};
        this.canUpload = false;
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', err.message);
        return Promise.reject(err);
      });
    },
    // アップロードキャンセル
    cancel() {
      this.res = {};
      this.uploadData = {};
      this.canUpload = false;
      this.isError = false;
    },
    cancelConfirm() {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          this.cancel();
        })
        .catch(() => {});
    },
    // アップロードBFF
    async confirm() {
      this.$store.commit('START_PROCESS');

      // BFF_LCP01 L/Cプール登録BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/lc-pool-datas',
        },
        data: this.uploadData,
      };
      await $api.request(params)
      .then(async res => {
        const errMessage = _.get(res, 'errors.message');
        if (errMessage) {
          if (res.lcpools && res.lcpools.length > 0) {
            this.$set(this.res, 'linkageLc', {
              lcpools: res.lcpools,
            });
          }
          this.isError = true;
          this.$store.dispatch('SHOW_ALERT', errMessage);
          this.$store.commit('END_PROCESS');
          this.$emit('fetch');
        } else {
          this.$store.dispatch('SHOW_COMPLETED');
          this.end();
          this.$emit('fetch');
        }
      })
      .catch(async err => {
        this.end();
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 指定したオブジェクトの中身を一階層上に展開
    // flatten(ary, target) {
    //   return _.map(ary, o => {
    //     return {..._.omit(o, [target]), ...o.lcdata};
    //   });
    // },
  }
};
</script>

<style lang="scss" scoped>
.attach {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
}

.attachment {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
  padding: 16px;

  .choose_file {
    position: relative;
    display: inline-block;

    .file_input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
      pointer-events: none;
    }
  }

  .droparea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 138px;
    margin: 16px 0 8px;
    background: $color_gray_100;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $color_dark_blue;
    pointer-events: none;
    z-index: 2;
    img {
      display: block;
      width: 84px;
      height: auto;
      margin-bottom: 9px;
    }
  }

  &.enter > * {
    pointer-events: none;
  }

  &.enter .droparea {
    border: 1px dashed $color_dark_blue;
  }
}

::v-deep .side_drawer {
  width: auto;
  width: 990px;

  >.inner {
    padding: 30px 40px 30px 35px;
  }
}

.tw_upload_drawer {
  margin: 40px 0 16px;
  max-width: 50%;
}

.view_area {
  padding: 24px;
  div {
    margin-bottom: 8px;
  }
}

.buttons {
  display: flex;
  justify-content: right;
  padding: 16px 32px 0;
}
</style>
