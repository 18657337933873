<template>
  <div class="pool_list">
    <tw-header :title="$t('BreadCrumbs.L/C List')">
      <template v-slot:column>
        <tw-button v-if="canUpload" type="secondary" size="small" icon="plus" style="margin-left: 24px" @click="showUpload()">
          Upload
        </tw-button>
      </template>
    </tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />

      <TwLcUpload :isShowUpload="isShowUpload" @close="closeUpload" @fetch="fetch" />

      <el-form v-if="!forbidden" class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <template v-if="isShipper">
            <el-form-item :label="$t('Label.Registered Date')" prop="poolDataUploadDate">
              <el-date-picker v-model="searchForm.poolDataUploadDate" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('Label.L/C No')" prop="lcNo">
              <el-input type="text" v-model="searchForm.lcNo" placeholder="Enter keyword" maxlength="300"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Currency Code')" prop="currencyCode">
              <el-input type="text" v-model="searchForm.currencyCode" placeholder="Enter keyword" maxlength="300"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Invoice Amount')" prop="currencyCodeAmount">
              <div class="el-date-editor el-range-editor el-input__inner el-date-editor--daterange amount_range" style="width: 235px;padding-left:4px">
                <el-input-number
                  :controls="false"
                  placeholder="Min"
                  name=""
                  class="el-range-input"
                  v-model="searchForm.amountMin"
                  :min="-maxNum"
                  :max="maxNum"
                />
                <span class="el-range-separator">to</span>
                <el-input-number
                  :controls="false"
                  placeholder="Max"
                  name=""
                  class="el-range-input"
                  v-model="searchForm.amountMax"
                  :min="-maxNum"
                  :max="maxNum"
                />
              </div>
            </el-form-item>
            <el-form-item :label="$t('Label.Date of Issue')" prop="dateOfIssue">
              <el-date-picker v-model="searchForm.dateOfIssue" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('Label.Date of Amendment')" prop="dateOfAmendment">
              <el-date-picker v-model="searchForm.dateOfAmendment" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :label="$t('Label.Company ID')" prop="companyId">
              <el-input type="text" v-model.trim="searchForm.companyId" placeholder="Enter keyword" maxlength="300"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Company Name')" prop="companyName">
              <el-input type="text" v-model="searchForm.companyName" placeholder="Enter keyword" maxlength="300"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Our Ref No (Original)')" prop="ourReferenceNoLcOriginal">
              <el-input type="text" v-model="searchForm.ourReferenceNoLcOriginal" placeholder="Enter keyword" maxlength="300"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Serial No')" prop="allNotificationSerialNumbers">
              <el-input type="text" v-model="searchForm.allNotificationSerialNumbers" placeholder="Enter keyword" maxlength="300"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.Seq No of Total')" prop="sequenceNumberOfTotal">
              <el-input type="text" v-model="searchForm.sequenceNumberOfTotal" placeholder="Enter keyword" maxlength="300"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Label.MT')" prop="messageType">
              <el-select v-model="searchForm.messageType" :class="{ multiple_selected: multipleSelect(searchForm.messageType) }" placeholder="Select" multiple collapse-tags clearable>
                <el-option v-for="messageType in MESSAGE_TYPE_VARIABLES" :key="messageType.code" :label="messageType.label" :value="messageType.code"> </el-option>
              </el-select>
            </el-form-item>
          </template>

          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <template v-if="isShipper">
                <el-form-item :label="$t('Label.Company ID')" prop="companyId">
                  <el-input type="text" v-model.trim="searchForm.companyId" placeholder="Enter keyword" maxlength="300"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.Company Name')" prop="companyName">
                  <el-input type="text" v-model="searchForm.companyName" placeholder="Enter keyword" maxlength="300"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.Our Ref No (Original)')" prop="ourReferenceNoLcOriginal">
                  <el-input type="text" v-model="searchForm.ourReferenceNoLcOriginal" placeholder="Enter keyword" maxlength="300"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.Serial No')" prop="allNotificationSerialNumbers">
                  <el-input type="text" v-model="searchForm.allNotificationSerialNumbers" placeholder="Enter keyword" maxlength="300"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.Seq No of Total')" prop="sequenceNumberOfTotal">
                  <el-input type="text" v-model="searchForm.sequenceNumberOfTotal" placeholder="Enter keyword" maxlength="300"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.MT')" prop="messageType">
                  <el-select v-model="searchForm.messageType" :class="{ multiple_selected: multipleSelect(searchForm.messageType) }" placeholder="Select" multiple collapse-tags clearable>
                    <el-option v-for="messageType in MESSAGE_TYPE_VARIABLES" :key="messageType.code" :label="messageType.label" :value="messageType.code"> </el-option>
                  </el-select>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item :label="$t('Label.Registered Date')" prop="poolDataUploadDate">
                  <el-date-picker v-model="searchForm.poolDataUploadDate" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('Label.L/C No')" prop="lcNo">
                  <el-input type="text" v-model="searchForm.lcNo" placeholder="Enter keyword" maxlength="300"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.Currency Code')" prop="currencyCode">
                  <el-input type="text" v-model="searchForm.currencyCode" placeholder="Enter keyword" maxlength="300"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.Invoice Amount')" prop="currencyCodeAmount">
                  <div class="el-date-editor el-range-editor el-input__inner el-date-editor--daterange amount_range" style="width: 235px;padding-left:4px">
                    <el-input-number
                      :controls="false"
                      placeholder="Min"
                      name=""
                      class="el-range-input"
                      v-model="searchForm.amountMin"
                      :min="-maxNum"
                      :max="maxNum"
                    />
                    <span class="el-range-separator">to</span>
                    <el-input-number
                      :controls="false"
                      placeholder="Max"
                      name=""
                      class="el-range-input"
                      v-model="searchForm.amountMax"
                      :min="-maxNum"
                      :max="maxNum"
                    />
                  </div>
                </el-form-item>
                <el-form-item :label="$t('Label.Date of Issue')" prop="dateOfIssue">
                  <el-date-picker v-model="searchForm.dateOfIssue" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('Label.Date of Amendment')" prop="dateOfAmendment">
                  <el-date-picker v-model="searchForm.dateOfAmendment" type="daterange" range-separator="to" format="yyyy-MM-dd" :default-value="defaultDate" @change.native="onDateRangeChange" start-placeholder="Start date" end-placeholder="End date"> </el-date-picker>
                </el-form-item>
              </template>
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.Applicant')" prop="applicant">
                <el-input type="text" v-model="searchForm.applicant" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Advising Bank ID')" prop="advisingBankId">
                <el-input type="text" v-model="searchForm.advisingBankId" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Advising Bank Name')" prop="advisingBank">
                <el-input type="text" v-model="searchForm.advisingBank" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Bank Branch No')" prop="bankBranchNo">
                <el-input type="text" v-model="searchForm.bankBranchNo" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Beneficiary No')" prop="beneficiaryNo">
                <el-input type="text" v-model="searchForm.beneficiaryNo" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.Issuing Bank')" prop="issuingBank">
                <el-input type="text" v-model="searchForm.issuingBank" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Confirming Bank')" prop="requestedConfirmationParty">
                <el-input type="text" v-model="searchForm.requestedConfirmationParty" placeholder="Enter keyword" maxlength="300"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Linking Status')" prop="linkingFlag">
                <el-select v-model="searchForm.linkingFlag" placeholder="Select" collapse-tags clearable>
                  <el-option
                    v-for="status in LC_LINK_FLG_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label=" " prop="confirmedLc">
                <el-checkbox border class="border_none" v-model="searchForm.confirmedLc" true-label="Y" :false-label="null">{{$t('Label.Confirmed L/C')}}</el-checkbox>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>
          <el-dropdown v-if="items && items.length > 0" trigger="click" placement="bottom" @command="onDropDown" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
              <el-dropdown-item command="tsvData">{{$t('Label.L/C Batch Download')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system
        :schemas="schemas"
        :items="items"
        :pageSize="limit"
        :totalCount="totalCount"
        :offset="offset"
        :serverPagination="true"
        @row-click="rowClick"
        @paging="onPaging"
        ref="table"
        mode="POOL"
      />
    </div>

    <tw-drawer :close-confirm="true" @close="closeConfirm" mode="edit">
      <template slot>
        <tw-lc-detail-inner
          v-if="drawerShow"
          :detailParams="detailParams"
          @deleted="deleted"
          @close="closeConfirm"
        />
      </template>
    </tw-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import { LC_LINK_FLG_VARIABLES, COMPANY_ROLE_CD, DELETE_FLG, DEFAULT_PAGENATION } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { moveAt, numberToString, clearSearchForm } from '@/utils/searchUtil.js';
import { collapseTransition } from '@/utils/nextFrame';
import { tsvDownLoad } from '@/utils/listToTsvUtils.js';
import schemas from '@/dictionaries/listLcPoolSchema.json';
import TwLcUpload from '@/components/organisms/TwLcUpload';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwLcDetailInner from '@/components/templates/TwLcDetailInner';
import { mapState } from 'vuex';
import dayjs from 'dayjs';

// import items from '@/../public/listLcPoolItem.json'; // スタブ
// let _items = [];
// for (let i = 0; i < 3; i++) {
//   _items = [..._items, ...items];
// }

export default {
  name: 'ListLcPool',
  mixins: [mixinEntityList, collapseTransition],
  components: {
    TwLcUpload,
    TwDrawer,
    TwLcDetailInner,
  },
  //初期値の設定
  data() {
    return {
      items: null,
      res: null,
      selection: false, // 一括削除時に切り替え
      searchForm: {
        messageType: [],
        sequenceNumberOfTotal: '',
        ourReferenceNoLcOriginal: '',
        allNotificationSerialNumbers: '',
        lcNo: '',
        currencyCode: '',
        amountMin: undefined,
        amountMax: undefined,
        dateOfIssue: '',
        dateOfAmendment: '',
        applicant: '',
        advisingBankId: '',
        advisingBank: '',
        issuingBank: '',
        requestedConfirmationParty: '',
        confirmedLc: null,
        poolDataUploadDate: '',
        companyId: '',
        companyName: '',
        bankBranchNo: '',
        beneficiaryNo: '',
        deleteFlg: '',
        linkingFlag: null,
        includeDeletedData: false,
      },
      detailParams: {
        sequenceNumberOfTotal: '',
        ourReferenceNoLcOriginal: '',
        allNotificationSerialNumbers: '',
        senderCompanyId: '',
        messageType: '',
        trx: '',
        canDeleteFlg: '',
      },
      maxNum: 10 ** 16, // 300桁
      // pageNum: 0,
      // pageLine: 0,
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      // sort: null,
      // count: 0,
      totalCount: 0,
      currentPage: 1,
      lastQuery: {},
      LC_LINK_FLG_VARIABLES: LC_LINK_FLG_VARIABLES,
      DELETE_FLG: DELETE_FLG,
      isShowUpload: false,
      drawerShow: false,
      forbidden: false, // 認可エラーフラグ
      // messageType区分値
      MESSAGE_TYPE_VARIABLES: [{ label: 'CoverLetter',  code: 'CLT'}, { label: '700',  code: '700'}, { label: '701',  code: '701'}, { label: '707',  code: '707'}, { label: '708',  code: '708'}, { label: '710',  code: '710'}, { label: '711',  code: '711'}, { label: '799',  code: '799'}],
    };
  },
  computed: {
    ...mapState(['roleInfo', 'companyRoles']),
    // 荷主か
    isShipper() {
      return this.$store.getters.isShipper;
    },
    isPic() {
      // ユーザーロールが担当者か
      return this.$store.getters.isPic;
    },
    canUpload() {
      // 添付ファイルアップロード可能カンパニーロール 添付可能対象ユーザ：銀行
      const isRoles = _.some(this.companyRoles, cd => {
        return [COMPANY_ROLE_CD.NEGOTIATING_BANK].includes(cd);
      });
      if (!isRoles) return false;
      // 担当者ロールかつ、プールセクションに属しているユーザのみアップロード可能：[LC]が先頭に含まれるセクションIDを持つユーザ
      return this.isPic && _.some(this.roleInfo.all, obj => {
        return obj.sectionId && obj.sectionId.startsWith('LC');
      });
    },
    schemas() {
      // 荷主の場合はL/C Noを3列目にして固定
      if (this.isShipper) {
        const index = _.findIndex(schemas, {key: 'documentaryCreditNumber'});
        _.find(schemas, {key: 'documentaryCreditNumber'}).fixed = true;
        return moveAt([...schemas], index, 2);
      }
      return schemas;
    },
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.L/C List')},
      ];
    },
  },
  created() {
    if (this.$store.state.drawerShow) this.$store.commit('SET_DRAWER', false);
    const queries = this.$store.getters.getQueries('LC_POOL');
    if (queries) {
      this.searchForm = queries;
    } else {
      // 検索初期条件：直近1ヶ月昇順
      const today = dayjs().hour(0).minute(0).second(0);
      this.searchForm.poolDataUploadDate = [
        today.subtract(1, 'month').format(),
        today.format(),
      ]
    }
    this.fetch();
  },
  methods: {
    // BFFに渡す入力値の設定
    transformQueries() {
      const f = this.searchForm;
      return {
        messageType: f.messageType,
        sequenceNumberOfTotal: f.sequenceNumberOfTotal,
        ourReferenceNoLcOriginal: f.ourReferenceNoLcOriginal,
        allNotificationSerialNumbers: f.allNotificationSerialNumbers,
        documentaryCreditNumber: f.lcNo,
        currencyCode: f.currencyCode,
        currencyCodeAmountFrom: numberToString(f.amountMin),
        currencyCodeAmountTo: numberToString(f.amountMax),
        dateOfIssueFrom: f.dateOfIssue && f.dateOfIssue.length ? dayjs(this.getStartDate(f.dateOfIssue)).format('YYMMDD') : null,
        dateOfIssueTo: f.dateOfIssue && f.dateOfIssue.length ? dayjs(this.getEndDate(f.dateOfIssue)).format('YYMMDD') : null,
        amendmentDateFrom: f.dateOfAmendment && f.dateOfAmendment.length ? dayjs(this.getStartDate(f.dateOfAmendment)).format('YYMMDD') : null,
        amendmentDateTo: f.dateOfAmendment && f.dateOfAmendment.length ? dayjs(this.getEndDate(f.dateOfAmendment)).format('YYMMDD') : null,
        applicant: f.applicant,
        advisingBankId: f.advisingBankId,
        advisingBankName: f.advisingBank,
        issuingBankName: f.issuingBank,
        requestedConfirmationParty: f.requestedConfirmationParty,
        confirmedLc: f.confirmedLc,
        registeredDateFrom: this.getStartDate(f.poolDataUploadDate),
        registeredDateTo: this.getEndDate(f.poolDataUploadDate),
        ownerCompanyId: f.companyId,
        ownerCompanyName: f.companyName,
        bankBranchNo: f.bankBranchNo,
        beneficiaryNo: f.beneficiaryNo,
        linkingFlag: f.linkingFlag,
        includeDeletedData: f.includeDeletedData,
      };
    },
    async fetch() {
      // BFF_LCP02 L/Cプール一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/lc-pool-datas/search/{pageLine}/{pageNum}',
          path: {
            pageLine: this.limit,
            pageNum: this.currentPage,
          },
          query: this.transformQueries(),
        },
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      // スタブ
      // await new Promise((resolve) => setTimeout(resolve, 2000)) // BFF疎通したら削除 確認用にN秒待つ
      // console.log(params)
      // this.items = _items;

      $api.request(params)
        .then((res) => {
          this.items = this.flatten(res.lcpools, 'lcmeta');
          this.totalCount = res.maxNum;
        })
        .catch((err) => {
          this.items = [];
          this.totalCount = 0;
          if (err.statusCode === 403) {
            this.forbidden = true;
          }
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject();
        });
    },
    search() {
      this.offset = 0;
      this.currentPage = 1;
      this.$store.commit('SET_QUERIES', { key: 'LC_POOL', queries: this.searchForm });
      this.items = null;
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.searchForm.linkingFlag = null;
      this.$store.commit('SET_QUERIES', { key: 'LC_POOL', queries: this.searchForm });
    },
    // eslint-disable-next-line no-unused-vars
    onPaging({target, sortType, currentPage}) {
      this.offset = currentPage * this.limit - this.limit;
      this.currentPage = currentPage;
      const queries = {
        ...this.lastQuery,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // BFF_LCP02 L/Cプール一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-pooldata-bff-api',
            apiCode: 'get_/v1/lc-pool-datas/search/{pageLine}/{pageNum}',
            path: { pageLine: this.totalCount, pageNum: 1 },
            query: { ...this.transformQueries() }
          }
        };
        $api.request(params)
        .then(res => {
          resolve(this.flatten(res.lcpools, 'lcmeta'));
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    },
    // 詳細TSVダウンロード TSVファイル出力共通BFF
    tsvDataDownload(pools) {
      const lcpools = _.map(pools, pool => {
        return {
          senderCompanyId: pool.senderCompanyId,
          ourReferenceNoLcOriginal: pool.ourReferenceNoLcOriginal,
          allNotificationSerialNumbers: pool.allNotificationSerialNumbers,
          sequenceNumberOfTotal: pool.sequenceNumberOfTotal,
          trx: pool.trx,
          messageType: pool.messageType,
        }
      });
      const query = {
        lcpools: lcpools,
      };
      const fileName = `LC_ALL.tsv`;
      // BFF_LCP07 TSVファイル出力共通BFF
      const apiCode = 'post_/v1/lc-pool-datas/download';
      $api
      .tsvDownload(query, fileName, 'tw-pooldata-bff-api', apiCode)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // アップロードエリアを表示します
    showUpload() {
      this.isShowUpload = true;
    },
    closeUpload() {
      this.isShowUpload = false;
    },
    rowClick(row) {
      this.detailParams.sequenceNumberOfTotal = row.sequenceNumberOfTotal;
      this.detailParams.ourReferenceNoLcOriginal = row.ourReferenceNoLcOriginal;
      this.detailParams.allNotificationSerialNumbers = row.allNotificationSerialNumbers;
      this.detailParams.senderCompanyId = row.senderCompanyId;
      this.detailParams.messageType = row.messageType;
      this.detailParams.trx = row.trx;
      this.detailParams.canDeleteFlg = row.canDeleteFlg;
      this.drawerShow = true;
      this.$store.commit('SET_DRAWER', true);
    },
    // ドロワーを閉じる
    closeConfirm() {
      this.$store.commit('SET_DRAWER', false);
      this.drawerShow = false;
    },
    // ドロワー内で許可書削除後
    deleted() {
      this.fetch()
      .finally(() => {
        this.closeConfirm();
        this.$store.commit('END_PROCESS');
      })
    },
    // ドロップダウン
    async onDropDown(command) {
      if (command === 'tsv') {
        const items = await this.fetchAll();
        tsvDownLoad(this.schemas, items);
      } else if (command === 'tsvData') {
        const items = await this.fetchAll();
        this.tsvDataDownload(items);
      }
    },
    // 指定したオブジェクトの中身を一階層上に展開
    flatten(ary, target) {
      return _.map(ary, o => {
        return {..._.omit(o, [target]), ...o.lcmeta};
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .side_drawer {
  width: auto;
  min-width: 682px;

  &.edit {
    width: 1082px;
  }
}
</style>
