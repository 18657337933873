<template>
  <div class="lc_detail_inner pool_detail" ref="inner">
    <header :class="{ deleted: isDelete }">
      <h1>
        {{$t('Label.L/C File')}}
      </h1>
      <img v-if="isDelete" class="delete" src="@/assets/images/delete.png">
      <el-dropdown v-if="res && Object.keys(res).length" class="drawer" trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
        <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
        <el-dropdown-menu class="permit_drawer nowrap">
          <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
          <!-- <el-dropdown-item v-if="!isLinking && !isDelete && editableFlag" command="delete">Delete</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </header>

    <tw-button v-if="res && Object.keys(res).length" type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
      <img src="@/assets/images/icons/view_all.svg">
    </tw-button>

    <table class="card">
      <template v-if="res && Object.keys(res).length">
        <TwLcPoolProcessList :initialOpen="true" :initSelectLc="true" ref="processList" />
      </template>
    </table>

    <div v-if="isLinking" class="card _linking">
      <div class="item">
        <div class="title">Trading/FlowID</div>
        <div class="value">{{getTradingFlowId()}}</div>
      </div>
      <div class="item">
        <div class="title">Trading Name</div>
        <div class="value">{{res.tradingName}}</div>
      </div>
      <div class="item">
        <div class="title">Flow Name</div>
        <div class="value">{{res.tradingManageName}}</div>
      </div>
      <!-- TODO: Ph3で非表示に 次Ph以降で対応 -->
      <!-- <div class="item">
        <div class="title">Invoice No</div>
        <div class="value">{{res.invoiceNo}}</div>
      </div> -->
      <div class="item process">
        <div class="title">Process</div>
        <div class="value link" @click="link">{{res.processName}}</div>
      </div>
      <div class="item">
        <div class="title">From - To</div>
        <div class="value flex">
          <div class="company">
            <figure class="avatar">
              <img :src="res.fromCompanyIcon" v-default-src="'user'">
            </figure>
            <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
              <div slot="content">
                <div class="company_name">{{res.fromCompanyName}}<br />{{res.fromSectionName}}</div>
              </div>
              <p class="name">{{res.fromCompanyShortName}}</p>
            </el-tooltip>
          </div>
          <div class="while">-</div>
          <div class="company">
            <figure class="avatar">
              <img :src="res.toCompanyIcon" v-default-src="'user'">
            </figure>
            <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
              <div slot="content">
                <div class="company_name">{{res.toCompanyName}}<br />{{res.toSectionName}}</div>
              </div>
              <p class="name">{{res.toCompanyShortName}}</p>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <!-- <tw-to-top v-if="isIpDnHistory && mounted" :container="$refs.inner" class="detail-to-top" /> -->
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import TwLcPoolProcessList from '@/components/organisms/TwLcPoolProcessList';
// import TwToTop from '@/components/atoms/TwToTop';
import TwButton from '@/components/atoms/TwButton';
import { DELETE_FLG, LC_LINK_FLG } from 'lib-tw-common';


export default {
  name: 'TwLcDetailInner',
  components: {
    TwLcPoolProcessList,
    // TwToTop,
    TwButton,
  },
  provide() {
    return {
      s: this,
      activeTab: null
    }
  },
  props: {
    detailParams: Object,
    canDeleteFlg: Boolean,
  },
  data() {
    return {
      res: {},
      expand: true,
      mounted: false,
      dragin: false,
      isDeleting: false, // 添付ファイル削除中フラグ
    };
  },
  computed: {

    // 削除済かどうか
    isDelete() {
      return _.get(this.res, 'deleteFlag') === String(DELETE_FLG.DELETED);
    },
    // 紐付け済かどうか
    isLinking() {
      return _.get(this.res, 'linkingFlag') === String(LC_LINK_FLG.LINKED);
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    async fetch() {
      // BFF_LCP04 L/Cプール詳細照会BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/lc-pool-datas/detail',
        },
        data: {
          lcpools: [{...this.detailParams}],
        },
      };
      $api.request(params)
      .then(res => {
        this.res = _.get(res, 'lcpools[0].lcmeta', {});
        this.$set(this.res, 'linkageLc', {
          lcpools: res.lcpools,
        });
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    // プロセス詳細へ遷移
    link() {
      this.$router.push({
        name: `Process${_.capitalize(this.res.processId.replace(/\d/g, ''))}`,
        params: { entityId: this.res.entityId, processSeq: this.res.processSeq, _processId: this.res.processId.toLowerCase() },
      })
    },
    // ドロップダウンメニュー
    onDropDownClick(command) {
      if (command === 'tsv') this.tsvDownload();
      else {
        this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => this.deletePermit())
        .catch(() => {});
      }
    },
    // TSVダウンロード
    tsvDownload() {
      const query = {
        lcpools: [{...this.detailParams}]
      };
      const fileName = `LC_${this.detailParams.messageType || ''}.tsv`;
      // BFF_LCP07 TSVファイル出力共通BFF
      const apiCode = 'post_/v1/lc-pool-datas/download';
      $api
      .tsvDownload(query, fileName, 'tw-pooldata-bff-api', apiCode)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 削除BFF
    // deletePermit() {
    //   this.$store.commit('START_PROCESS');
    //   // TODO: BFF疎通後確認
    //   const deleteItem = {
    //     docType: this.docType || '',
    //     senderCompanyId: this.senderCompanyId || '',
    //     insurancePolicyNo: this.insurancePolicyNo || '',
    //     insurancePolicyBranchNo: this.insurancePolicyBranchNo || '',
    //     ipDnType: this.ipDnType || '',
    //     trx: this.trx || '',
    //   }
    //   const params = {
    //     lslConfig: {
    //       // TODO: エンドポイント変更
    //       serviceCode: 'tw-pooldata-bff-api',
    //       apiCode: 'post_/v1/nc-pool-datas/export/delete', // v1/ip-pool-datas/ip/delete
    //       data: {
    //         ippools: [deleteItem],
    //       },
    //     }
    //   };
    //   $api.request(params)
    //   .then(async (res) => {
    //     const status = _.get(res, 'ippools[0].status');
    //     if ((status < 200 || 299 < status)) {
    //       this.$store.dispatch('SHOW_ALERT', _.get(res, 'ippools[0].errors.message', ''));
    //     }
    //     this.$emit('deleted');
    //   })
    //   .catch(err => {
    //     this.$store.dispatch('SHOW_ALERT', err.message);
    //     this.$store.commit('END_PROCESS');
    //   });
    // },
    getTradingFlowId() {
      const tradingId = _.get(this.res, 'tradingId', '');
      const flowId = _.get(this.res, 'tradingFlowId', '');
      return `${tradingId ? tradingId + '/' : ''}${flowId || ''}`;
    },
    // 指定したオブジェクトの中身を一階層上に展開
    // flatten(ary, target) {
    //   return _.map(ary, o => {
    //     return {..._.omit(o, [target]), ...o.lcdata};
    //   });
    // },
  }
}
</script>

<style lang="scss" scoped>
.lc_detail_inner {
  padding: 30px 35px;
  position: relative;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .delete {
      margin: -30px 32px 0 auto;
    }

    &.deleted {
      flex-wrap: wrap;
      .drawer.el-dropdown {
        // width: 100%;
        .el-dropdown-link.ellipsis {
          margin: 0 16px 0 auto;
        }
      }
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .column {
      height: 41px;
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      &:last-child {
        justify-content: flex-end;
      }
    }
    .tw_entity_icon {
      margin-right: 8px;
    }
    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      margin-right: 8px;
    }
    .tw_status_icon {
      margin-right: 24px;
    }
    .tw_button {
      margin-right: 24px;
    }
  }
  table.card {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0;
    overflow: hidden;
    margin-bottom: 20px;
    // box-shadow: 0px 4.11278px 8.22556px rgba(170, 170, 204, 0.5);
    &.excustom {
      margin-bottom: 32px;
      ::v-deep .tw_process_list > .inner {
        display: none;
      }
    }
  }
  .card._linking {
    margin: 40px 0;
    padding: 24px;
    box-shadow: none;
    border: 1px solid $color_gray_300;
    border-radius: 6px;
    .item {
      display: flex;
      font-size: 14px;
      margin-bottom: 24px;
      &:hover {
        background: white;
        .value {
          color: $color_black;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        width: 40%;
        color: $color_gray_800;
      }
      .value.flex {
        display: flex;
        align-items: center;
        .while {
          margin: 0 16px;
        }
      }
      .company {
        display: flex;
        align-items: center;
        figure.avatar {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
        .name {
          max-width: 58px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.process {
        .value {
          color: $color_dark_blue;
          &.link {
            cursor: pointer;
          }
        }
      }
    }
  }

  &.ipdn_history {
    height: 100vh;
    overflow-y: scroll;
  }
}
</style>
<style lang="scss">
.el-dropdown-menu.el-popper.permit_drawer {
  z-index: 3000 !important;
}
</style>
